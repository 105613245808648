enum GenderEnum {
  male = "男性",
  female = "女性",
  unknown = "未設定",
}

const AllCustomerStatusesConstants = {
  all: "すべて",
  new: "新規",
  existed: "既存",
  defection: "離反",
  hibernation: "休眠",
  revisited: "再来",
};

const BasicCustomerFilterFields = ["name", "code"];

enum CustomerFilterLineBy {
  linked = "linked",
  unlinked = "unlinked",
  all = "all",
}

enum linkedLineStatus {
  notYet = "紐付けなし",
  linked = "紐付け済み",
}

const CustomerStatusOptions = Object.entries(AllCustomerStatusesConstants).map(([value, name]) => ({
  name,
  value,
}));

const CustomerDuplicateCheckFields: Record<string, any> = {
  name: { en_name: "name", jp_name: "名前" },
  phone: { en_name: "phone", jp_name: "電話番号" },
  birthday: { en_name: "birthday", jp_name: "生年月日" },
};

const PreviewMergedCustomerFieldNames = {
  code: "顧客ID",
  name: "名前",
  birthday: "生年月日",
  age: "年齢",
  phone: "電話番号",
  address: "住所",
  email: "メールアドレス",
  gender: "性別",
  customer_status: "顧客ステータス",
  visit_status: "来店ステータス",
  first_time_visit: "初回来店日",
  latest_time_visit: "最終来店日",
  total_visits_in_current_month: "当月内来店回数",
  visit_cycle_in_one_year: "来店周期日数",
  total_visits: "当年内来店回数",
  customer_notes: "顧客メモ",
  special_notes: "特記事項",
  system_tags: "オリジナルタグ",
  partner_tags: "RIPICLEタグ",
  penultimate_booking_services: "前回施術メニュー",
  latest_booking_services: "予約時の最新情報",
  booking_services_history: "過去の施術メニュー",
};

export {
  GenderEnum,
  linkedLineStatus,
  CustomerFilterLineBy,
  CustomerStatusOptions,
  BasicCustomerFilterFields,
  CustomerDuplicateCheckFields,
  PreviewMergedCustomerFieldNames,
};
