export default class RouterConstants {
  // Common routers path
  static SIGN_IN_ROUTER_PATH = "/login";
  static FORGOT_PASSWORD_ROUTER_PATH = "/forgot-password";
  static RESET_PASSWORD_ROUTER_PATH = "/reset-password";
  static CLINIC_ROOT_ROUTER_PATH = "/clinic";
  static CLINIC_DAILY_ROUTINE_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/daily-routine`;
  static DASHBOARD_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/dashboard`;
  static CAMPAIGN_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/campaigns`;
  static CAMPAIGN_NODATA_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/campaigns/nodata`;
  static CAMPAIGN_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/campaigns/new`;
  static CAMPAIGN_RESULT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/campaigns/:id`;
  static CONTACT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/contact`;
  static BOOKING_ROUTER_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/bookings`;
  static CUSTOMER_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/customers`;
  static CUSTOMER_CREATE_ROUTER_PATH = `${this.CUSTOMER_ROUTER_PATH}/new`;
  static CUSTOMER_EDIT_ROUTER_PATH = `${this.CUSTOMER_ROUTER_PATH}/:id/edit`;
  static SETTING_ROUTER_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/settings`;
  static SEGMENT_WORKFLOW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/segment-workflows`;
  static RICH_MENU_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/rich-menu`;
  static RICH_MENU_REGISTRATION_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/rich-menu/new`;
  static RICH_MENU_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/rich-menu/:id`;
  static MESSAGE_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/messages/new`;
  static MESSAGE_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/messages/:id/edit`;
  static MESSAGE_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/messages`;
  static DIRECT_MESSAGE_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/direct_messages`;
  static STAFF_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/staffs/new`;
  static STAFF_LIST_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/staffs`;
  static STAFF_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/staffs/:id/edit`;
  static STAFF_SHIFT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/settings/staff-shift`;
  static MENU_REGISTRATION_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/menus/new`;
  static MENU_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/menus/:id/edit`;
  static MENU_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/menus`;
  static BOOKING_SETTING_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/booking_setting`;
  static MEDICAL_OPTIONS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/medical_options`;
  static MEDICAL_OPTIONS_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/medical_options/new`;
  static MEDICAL_OPTIONS_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/medical_options/:id/edit`;
  static MEDICAL_MENU_CATEGORY_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/medical_menu_categories`;
  static LIVE_MESSAGE_TEMPLATE_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/live_message_templates`;
  static LIVE_MESSAGE_TEMPLATE_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/live_message_templates/new`;
  static LIVE_MESSAGE_TEMPLATE_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/live_message_templates/:id/edit`;
  static MANUAL_TAGS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/manual_tags`;
  static CLIENT_DETAILS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/client_details`;
  static LINE_NOTIFICATION_MESSAGE_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/line_notification_messages`;
  static LINE_NOTIFICATION_MESSAGE_NEW_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/line_notification_messages/new`;
  static LINE_NOTIFICATION_MESSAGE_EDIT_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/line_notification_messages/:id/edit`;
  static TAGGED_DELIVERY_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/tagged_delivery`;
  static DISTRIBUTION_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups`;
  static DISTRIBUTION_GROUPS_DRAFTS_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/drafts`;
  static NEW_DISTRIBUTION_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/new`;
  static EDIT_DISTRIBUTION_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/:id/edit`;
  static DUPLICATE_DISTRIBUTION_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/:id/duplicate`;
  static DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/routine`;
  static NEW_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/routine/new`;
  static EDIT_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/routine/:id/edit`;
  static DISTRIBUTION_GROUPS_ROUTINE_DRAFTS_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/routine/drafts`;
  static DUPLICATE_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/distribution_groups/routine/:id/duplicate`;
  static ROOMS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/rooms`;
  static TARGET_CUSTOMER_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/target_customers`;
  static STAFF_PRIORITIES_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/staff_priorities`;
  static SERVICE_TAGS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/service_tags`;
  static NEW_SERVICE_TAGS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/service_tags/new`;
  static BOOKING_NOTIFICATIONS_ROUTER_PATH = `${this.CLINIC_ROOT_ROUTER_PATH}/booking_notifications`;

  // Router name
  static SIGN_IN_ROUTER_NAME = "Login";
  static FORGOT_PASSWORD_ROUTER_NAME = "Forgot Password";
  static RESET_PASSWORD_ROUTER_NAME = "Reset Password";
  static DASHBOARD_ROUTER_NAME = "Dashboard";
  static BOOKING_ROUTER_NAME = "Bookings";
  static CLINIC_DAILY_ROUTINE_ROUTER_NAME = "Clinic daily routine";
  static CAMPAIGN_ROUTER_NAME = "Campaigns";
  static CAMPAIGN_NEW_ROUTER_NAME = "Campaign New";
  static CAMPAIGN_RESULT_ROUTER_NAME = "Campaign Result";
  static CAMPAIGN_NODATA_ROUTER_NAME = "Campaign Nodata";
  static CONTACT_ROUTER_NAME = "Contact";
  static CUSTOMER_ROUTER_NAME = "Customers";
  static CUSTOMER_CREATE_ROUTER_NAME = "Customers Create";
  static CUSTOMER_EDIT_ROUTER_NAME = "Customer Edit";
  static SETTING_ROUTER_NAME = "Settings";
  static SEGMENT_WORKFLOW_ROUTER_NAME = "Segment Workflow";
  static RICH_MENU_ROUTER_NAME = "Rich Menu";
  static RICH_MENU_REGISTRATION_ROUTER_NAME = "Rich Menu Registration";
  static RICH_MENU_EDIT_ROUTER_NAME = "Rich Menu Edit";
  static MESSAGE_NEW_ROUTER_NAME = "Message New";
  static MESSAGE_EDIT_ROUTER_NAME = "Message Edit";
  static MESSAGE_ROUTER_NAME = "Message";
  static DIRECT_MESSAGE_ROUTER_NAME = "Direct Message";
  static STAFF_NEW_ROUTER_NAME = "Staff New";
  static STAFF_LIST_ROUTER_NAME = "Staffs";
  static STAFF_EDIT_ROUTER_NAME = "Staff Edit";
  static STAFF_SHIFT_ROUTER_NAME = "Staff Shift";
  static MENU_REGISTRATION_ROUTER_NAME = "Menu Registration";
  static MENU_EDIT_ROUTER_NAME = "Menu Edit";
  static MENU_ROUTER_NAME = "Menus";
  static BOOKING_SETTING_ROUTER_NAME = "Booking Setting";
  static MEDICAL_OPTIONS_ROUTER_NAME = "Medical Options";
  static MEDICAL_OPTIONS_NEW_ROUTER_NAME = "Medical Option New";
  static MEDICAL_OPTIONS_EDIT_ROUTER_NAME = "Medical Option Edit";
  static MEDICAL_MENU_CATEGORY_ROUTER_NAME = "Medical Menu Categories";
  static LIVE_MESSAGE_TEMPLATE_ROUTER_NAME = "Live Message Templates";
  static LIVE_MESSAGE_TEMPLATE_NEW_ROUTER_NAME = "Live Message Template New";
  static LIVE_MESSAGE_TEMPLATE_EDIT_ROUTER_NAME = "Live Message Template Edit";
  static MANUAL_TAGS_ROUTER_NAME = "Manual Tags";
  static CLIENT_DETAILS_ROUTER_NAME = "Client Details";
  static LINE_NOTIFICATION_MESSAGE_ROUTER_NAME = "Line Notification Messages";
  static LINE_NOTIFICATION_MESSAGE_NEW_ROUTER_NAME = "Line Notification Message New";
  static LINE_NOTIFICATION_MESSAGE_EDIT_ROUTER_NAME = "Line Notification Message Edit";
  static TAGGED_DELIVERY_ROUTER_NAME = "Tagged Delivery Name";
  static DISTRIBUTION_GROUP_ROUTER_NAME = "Distribution Group";
  static DISTRIBUTION_GROUPS_DRAFTS_NAME = "Distribution Groups Drafts";
  static NEW_DISTRIBUTION_GROUPS_NAME = "Distribution Groups New";
  static EDIT_DISTRIBUTION_GROUP_ROUTER_NAME = "Distribution Groups Edit";
  static DUPLICATE_DISTRIBUTION_GROUP_ROUTER_NAME = "Duplicate Distribution Group";
  static SERVICE_TAGS_ROUTER_NAME = "Service Tags";
  static TARGET_CUSTOMER_ROUTER_NAME = "Target Customers";
  static DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME = "Distribution Routine Groups";
  static NEW_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME = "New Distribution Routine Group";
  static EDIT_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME = "Edit Distribution Routine Group";
  static DISTRIBUTION_GROUPS_ROUTINE_DRAFTS_NAME = "Distribution Routine Groups Drafts";
  static DUPLICATE_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME = "Duplicate Routine Distribution Group";
  static STAFF_PRIORITIES_ROUTER_NAME = "Staff Priorities";
  static BOOKING_NOTIFICATIONS_ROUTE_NAME = "Booking Notifications";
  static ROOMS_ROUTE_NAME = "Rooms";
}
