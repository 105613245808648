export const CustomerHairProblems = [
  { en_name: "hair_damage", jp_name: "髪のダメージ" },
  { en_name: "straight_hair", jp_name: "直毛" },
  { en_name: "coarse_hair", jp_name: "髪が硬い" },
  { en_name: "lack_of_volume", jp_name: "ボリュームがない" },
  { en_name: "hair_frizz", jp_name: "髪の広がり" },
  { en_name: "curly_hair", jp_name: "クセっ毛" },
  { en_name: "gray_hair", jp_name: "白髪" },
  { en_name: "hair_loss", jp_name: "抜け毛" },
  { en_name: "thinning_hair", jp_name: "薄毛" },
  { en_name: "none", jp_name: "特にない" },
];

export const CustomerSkinProblems = [
  { en_name: "blackheads", jp_name: "毛穴の黒ずみ" },
  { en_name: "open_pores", jp_name: "毛穴の開き" },
  { en_name: "wrinkles", jp_name: "しわ" },
  { en_name: "spots", jp_name: "シミ" },
  { en_name: "sagging", jp_name: "たるみ" },
  { en_name: "dryness", jp_name: "乾燥" },
  { en_name: "acne", jp_name: "ニキビ" },
  { en_name: "skin_trouble", jp_name: "肌荒れ" },
  { en_name: "none", jp_name: "特にない" },
];
